<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 16:04:07
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-10 23:02:38
-->
<template>
  <div class="page-user">
    <div class="user-info">
      <div class="avatar">
        <el-image :src="$store.getters.avatar" alt="">
          <img slot="error" class="image-slot" :src="require('@/assets/images/default-avatar.png')" alt="">
        </el-image>
      </div>
      <div class="info">
        <div class="name">{{$store.getters.user_nickname}}</div>
        <div class="tel">{{$store.getters.mobile|mobileFormat}}</div>
      </div>
      <div class="action">
        <router-link :to="{name:'userEdit'}">编辑资料</router-link>
      </div>
    </div>
    <div class="collect-list">
      <div class="tab">
        <div class="item" :class="{active: item.id == active}" v-for="(item, index) in tabList" :key="index" @click="tapClick(item)">{{item.name}}</div>
      </div>
      <div class="content">
        <scroll-list :api="api" :query="query" ref="scrollList">
          <div slot-scope="scope">
            <template v-if="active == 5">
              <div class="list">
                <!-- {{ scope.list[0].data }} -->
                <div class="item" v-for="(item, index) in scope.list[0].data" :key="index" @click="goLiveDate(item)">
                  <div class="image" :style="'background-image:url('+item.thumbnail+')'">
                  </div>
                  <div class="context">
                    <div class="title">
                      <span>{{item.post_title}}</span>
                    </div>
                    <div class="dest">
                      <span>{{item.post_excerpt}}</span>
                    </div>
                    <div class="tag" v-if="item.post_keywords">
                      <i v-for="(d, idx) in item.post_keywords.split(',')" :key="idx">{{d}}</i>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <ul>
                <li v-for="item in scope.list" :key="item.id" @click="toQuestion(item)">
                  <div class="wrap">
                    <span class="type-name">
                      {{questionTypeMap[item.q_type]}}
                    </span>
                    <span class="label text-overflow-clamp-2" v-html="item.contents">
                    </span>
                  </div>
                </li>
              </ul>
            </template>
          </div>
        </scroll-list>
      </div>
    </div>
    <el-dialog :visible.sync="dialogTableVisible" append-to-body width="400px" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" custom-class="diaAtrel">
      <div class="contents">VIP用户才可以查看哦</div>
      <div class="footers">
        <div class="item q" @click="(dialogTableVisible=false)">取消</div>
        <div class="item c" @click="$router.push({name: 'advisory'})">去咨询</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import scrollList from '@/components/scrollList'
import dic from '@/util/dic'
import { getFavList, getFavorite } from '@/api/user'
export default {
  components: {
    scrollList
  },
  data() {
    return {
      dialogTableVisible: false,
      api: '',
      query: {},
      questionTypeMap: dic.questionTypeMap,
      active: 1,
      tabList: [
        {name: '我的收藏', id: 1},
        {name: 'VIP课程', id: 5}
      ],
    }
  },
  created() {
    this.api = getFavList;
    this.query = {
      uniqid: this.$store.getters.uniqid,
      type: this.active
    }
  },
  methods: {
    toQuestion(row) {
      if (!this.$store.getters.uniqid) {
        this.$root.Bus.$emit('showLogin')
        return
      }
      this.$router.push('/question/' + row.obj_id)
    },
    goLiveDate(item) {
      this.$router.push({name: 'liveDetail', query: {id: item.id}})
    },
    tapClick(item){
      if(Number(this.active) == Number(item.id)) return;
      this.active = item.id;
      if(item.id == 1){
        this.api = getFavList;
        this.query = {
          uniqid: this.$store.getters.uniqid,
          type: 1
        }
      }else{
        this.api = getFavorite;
        this.query = {
          uniqid: this.$store.getters.uniqid,
          is_vip: 1
        }
      }
      this.$refs.scrollList.page = 1;
      this.$refs.scrollList.list = [];
      setTimeout(() => {
        this.$refs.scrollList.getData();
      }, 100)
      
      
    }
  }
}
</script>
<style lang="scss" scoped>
.page-user {
  width: $contentWidth;
  max-width: 100%;
  margin: 0 auto;

  .user-info {
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background: #fff;
    .avatar {
      flex-shrink: 0;
      .el-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .info {
      flex: 1;
      padding-left: 15px;
      .name {
        font-size: 24px;
        font-weight: 500;
      }
      .tel {
        margin-top: 10px;
        font-size: 18px;
        color: $textSubColor;
      }
    }
    a {
      width: 5em;
      text-align: right;
      color: $mainColor;
      font-size: 18px;
    }
  }
  .collect-list {
    margin-top: 16px;
    background: #fff;
    min-height: 400px;
    .list {
    width: 100%;
      padding: 0 20px;
      padding-top: 20px;
    .item {
      width: 100%;
      padding: 30px 0;
      display: flex;
      border-bottom: 1px solid rgba(232, 232, 232, 1);
      cursor: pointer;
      &:first-child{
        padding-top: 0;
      }
      .context {
        padding-left: 20px;
        width: 100%;
        height: 180px;
        position: relative;

        .tag {
          display: flex;

          i {
            height: 20px;
            line-height: 20px;
            padding: 0 8px;
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            background: rgba(242, 242, 242, 1);
            display: inline-block;
            margin-right: 5px;
            font-style: normal;
          }
        }

        .dest {
          width: 650px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 15px 0;
          line-height: 1;
          span {
            color: rgba(102, 102, 102, 1);
            font-size: 14px;
          }
        }

        .title {
          width: 650px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1;
          span {
            color: rgba(0, 0, 0, 1);
            font-size: 16px;
          }
        }
      }

      .image {
        width: 270px;
        height: 180px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
    .tab{
      border-bottom: 1px solid #E6E6E6;
      display: flex;
      padding-left: 30px;
      .item{
        padding: 0.27rem 0 0.26rem;
        font-weight: 500;
        font-size: 0.24rem;
        margin-right: 60px;
        position: relative;
        color: #666666;
        cursor: pointer;
        &.active{
          color: #333333;
          &::after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 40px;
            height: 4px;
            background-color: #FF6A29;
          }
        }
      }
    }
  }
}
</style>